import React, { useState, useEffect, useRef } from 'react';
import { getAllMessages } from '../lib/messages';
import { config } from '../config';
import { searchIndex } from '../utils/searchIndex';

export default function DCLeak() {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const messages = getAllMessages();

  const searchTimeout = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const init = async () => {
      await searchIndex.initialize(messages);
      setIsInitializing(false);
    };
    
    const checkProgress = setInterval(() => {
      const progress = searchIndex.getProgress();
      setLoadingProgress(progress);
      if (progress >= 10) {
        setIsInitializing(false);
      }
      if (progress === 100) {
        clearInterval(checkProgress);
      }
    }, 100);

    init();
    return () => clearInterval(checkProgress);
  }, [messages]);

  useEffect(() => {
    if (isInitializing) return;

    if (!searchQuery.trim()) {
      setFilteredMessages(messages);
      setIsSearching(false);
      return;
    }

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    setIsSearching(true);
    
    searchTimeout.current = setTimeout(() => {
      try {
        const results = searchIndex.search(searchQuery, messages);
        setFilteredMessages(results || []);
        
        // If still loading, update results periodically
        if (searchIndex.isStillLoading()) {
          const interval = setInterval(() => {
            const updatedResults = searchIndex.search(searchQuery, messages);
            setFilteredMessages(prev => {
              // Only update if we have new results
              if (updatedResults.length > prev.length) {
                return updatedResults;
              }
              return prev;
            });
            
            if (!searchIndex.isStillLoading()) {
              clearInterval(interval);
            }
          }, 500);

          // Cleanup interval
          return () => clearInterval(interval);
        }
      } catch (error) {
        console.error('Search error:', error);
        setFilteredMessages([]);
      } finally {
        setIsSearching(false);
      }
    }, 200); // Reduced debounce time for better responsiveness

    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, [searchQuery, messages, isInitializing]);

  useEffect(() => {
    const handleScroll = async () => {
      if (isInitializing || isSearching || searchIndex.isStillLoading()) return;
      
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      
      // Load more when user scrolls near bottom (200px threshold)
      if (documentHeight - (scrollPosition + windowHeight) < 200) {
        const hasMore = await searchIndex.loadNextBatch();
        if (hasMore && searchQuery.trim()) {
          const results = searchIndex.search(searchQuery, messages);
          setFilteredMessages(results);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isInitializing, isSearching, searchQuery, messages]);

  return (
    <div className="w-full min-h-screen bg-[#f6f6f6]">
      <div 
        ref={containerRef}
        className="max-w-[1400px] mx-auto px-4 sm:px-6 md:px-[5vw] pt-[60px] sm:pt-[80px] md:pt-[100px]"
      >
        <div className="flex justify-between items-center mb-12 sm:mb-16 md:mb-24">
          <div className="text-lg sm:text-xl">DOMINI CANES LEAKS</div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={isInitializing ? "" : "SEARCH"}
            className="text-lg sm:text-xl bg-transparent focus:outline-none text-right w-[120px] sm:w-[200px]"
            disabled={isInitializing}
          />
        </div>

        {isInitializing ? (
          <div className="text-lg sm:text-xl opacity-50">
            Loading Leaks<span className="searching-dots"></span>
          </div>
        ) : (
          <div className="pb-20">
            {filteredMessages.map((group, groupIndex) => (
              <div key={groupIndex} className="flex flex-col gap-2 mb-8">
                <div className="text-lg sm:text-xl mb-4">{group.date}</div>
                {group.files.map((fileNumber, fileIndex) => (
                  <div key={fileIndex}>
                    <a 
                      href={`${config.baseUrl}${config.htmlPath}${fileNumber}.html`}
                      className="text-lg sm:text-xl hover:opacity-60 transition-opacity"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {fileNumber}
                    </a>
                  </div>
                ))}
              </div>
            ))}
            
            {searchIndex.isStillLoading() && (
              <div className="fixed bottom-0 left-0 right-0 bg-[#f6f6f6] py-20 text-center">
                <div className="max-w-[1400px] mx-auto px-4 sm:px-6 md:px-[5vw]">
                  <div className="text-lg sm:text-xl opacity-70">
                    Loading more<span className="searching-dots"></span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}