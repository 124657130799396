export class SearchIndex {
    constructor() {
        this.cache = new Map();
        this.initialized = false;
        this.progress = 0;
        this.isLoading = false;
        this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        this.maxCacheSize = this.isMobile ? 2000 : 5000;
        this.currentBatch = 0;
        this.batchSize = this.isMobile ? 20 : 50;
    }

    getProgress() {
        return this.progress;
    }

    isStillLoading() {
        return this.isLoading;
    }

    async initialize(messages) {
        if (this.initialized) return;
        this.isLoading = true;
        
        const allFiles = messages.flatMap(group => 
            group.files.map(fileNum => ({ fileNum, group }))
        );
        
        // Load just first batch initially
        const initialBatch = allFiles.slice(0, this.batchSize);
        await this.loadBatchWithRetry(initialBatch);
        this.progress = Math.floor((this.batchSize / allFiles.length) * 100);
        
        this.initialized = true;
        this.isLoading = false;
        this.totalFiles = allFiles;
    }

    async loadNextBatch() {
        if (!this.totalFiles || this.isLoading) return false;
        
        const start = (this.currentBatch + 1) * this.batchSize;
        const end = start + this.batchSize;
        
        if (start >= this.totalFiles.length) return false;
        
        this.isLoading = true;
        const batch = this.totalFiles.slice(start, end);
        await this.loadBatchWithRetry(batch);
        
        this.currentBatch++;
        this.progress = Math.floor((end / this.totalFiles.length) * 100);
        this.isLoading = false;
        
        return true;
    }

    search(query, messages) {
        if (!query.trim()) {
            return messages;
        }

        const lowerQuery = query.toLowerCase().trim();
        const results = new Set();

        messages.forEach(group => {
            let shouldAdd = false;
            
            if (group.date.toLowerCase().includes(lowerQuery)) {
                shouldAdd = true;
            }
            
            if (group.files.some(num => num.toString().includes(lowerQuery))) {
                shouldAdd = true;
            }

            for (const fileNum of group.files) {
                const content = this.cache.get(fileNum);
                if (content && content.toLowerCase().includes(lowerQuery)) {
                    shouldAdd = true;
                    break;
                }
            }

            if (shouldAdd) {
                results.add(group);
            }
        });

        return Array.from(results);
    }

    async loadBatchWithRetry(batch, maxRetries = 3) {
        for (const { fileNum } of batch) {
            if (!this.cache.has(fileNum)) {
                let retries = 0;
                while (retries < maxRetries) {
                    try {
                        const response = await fetch(`/HTML/messages${fileNum}.html`);
                        const text = await response.text();
                        this.cache.set(fileNum, text.toLowerCase());
                        break;
                    } catch (error) {
                        retries++;
                        if (retries === maxRetries) {
                            console.warn(`Failed to cache file ${fileNum} after ${maxRetries} attempts`);
                        }
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    }
                }
            }
        }
    }
}

export const searchIndex = new SearchIndex();