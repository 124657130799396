import { searchHtmlContent } from '../utils/htmlParser';

async function searchMessages(query) {
  const lowerCaseQuery = query.toLowerCase();
  const results = [];

  for (const item of messageData) {
    // Check date match
    const dateMatch = item.date.toLowerCase().includes(lowerCaseQuery);
    
    // Check file numbers
    const fileMatch = item.files.some(file => 
      file.toString().includes(lowerCaseQuery)
    );

    // Check file contents
    const contentMatches = await Promise.all(
      item.files.map(async (fileNum) => {
        const content = await searchHtmlContent(fileNum);
        return content.toLowerCase().includes(lowerCaseQuery);
      })
    );

    if (dateMatch || fileMatch || contentMatches.some(match => match)) {
      results.push(item);
    }
  }

  return results;
}

// Function to extract number from filename (e.g., "messages123.html" -> 123)
const getFileNumber = (filename) => {
  const match = filename.match(/messages(\d+)\.html/);
  return match ? parseInt(match[1]) : 0;
};

// Helper function to construct HTML path from file number
export function getMessagePath(fileNumber) {
  return `${window.location.origin}/HTML/messages${fileNumber}.html`;
}

const messageData = [
  { date: "October 22, 2024", files: [1447] },
  { date: "October 21, 2024", files: [1446] },
  { date: "October 20, 2024", files: [1445] },
  { date: "October 18, 2024", files: [1444, 1443] },
  { date: "October 17, 2024", files: [1442] },
  { date: "October 16, 2024", files: [1441] },
  { date: "October 15, 2024", files: [1440] },
  { date: "October 13, 2024", files: [1439, 1438, 1437] },
  { date: "October 11, 2024", files: [1436] },
  { date: "October 10, 2024", files: [1435] },
  { date: "October 09, 2024", files: [1434] },
  { date: "October 08, 2024", files: [1433] },
  { date: "October 07, 2024", files: [1432] },
  { date: "October 05, 2024", files: [1431] },
  { date: "October 04, 2024", files: [1430] },
  { date: "October 03, 2024", files: [1429] },
  { date: "October 02, 2024", files: [1428, 1427] },
  { date: "October 01, 2024", files: [1426] },
  { date: "September 30, 2024", files: [1425] },
  { date: "September 29, 2024", files: [1424] },
  { date: "September 28, 2024", files: [1423] },
  { date: "September 27, 2024", files: [1422] },
  { date: "September 25, 2024", files: [1421] },
  { date: "September 24, 2024", files: [1420] },
  { date: "September 23, 2024", files: [1419] },
  { date: "September 22, 2024", files: [1418] },
  { date: "September 20, 2024", files: [1417] },
  { date: "September 19, 2024", files: [1416] },
  { date: "September 18, 2024", files: [1415] },
  { date: "September 17, 2024", files: [1414] },
  { date: "September 16, 2024", files: [1413, 1412] },
  { date: "September 15, 2024", files: [1411] },
  { date: "September 13, 2024", files: [1410] },
  { date: "September 12, 2024", files: [1409] },
  { date: "September 11, 2024", files: [1408, 1407] },
  { date: "September 10, 2024", files: [1406, 1405] },
  { date: "September 08, 2024", files: [1404] },
  { date: "September 07, 2024", files: [1403] },
  { date: "September 06, 2024", files: [1402] },
  { date: "September 05, 2024", files: [1401] },
  { date: "September 04, 2024", files: [1400] },
  { date: "September 03, 2024", files: [1399] },
  { date: "September 02, 2024", files: [1398] },
  { date: "September 01, 2024", files: [1397] },
  { date: "August 30, 2024", files: [1396] },
  { date: "August 29, 2024", files: [1395] },
  { date: "August 28, 2024", files: [1394] },
  { date: "August 27, 2024", files: [1393] },
  { date: "August 26, 2024", files: [1392] },
  { date: "August 25, 2024", files: [1391] },
  { date: "August 23, 2024", files: [1390, 1389] },
  { date: "August 22, 2024", files: [1388] },
  { date: "August 21, 2024", files: [1387] },
  { date: "August 20, 2024", files: [1386] },
  { date: "August 19, 2024", files: [1385] },
  { date: "August 18, 2024", files: [1384] },
  { date: "August 16, 2024", files: [1383] },
  { date: "August 15, 2024", files: [1382] },
  { date: "August 14, 2024", files: [1381] },
  { date: "August 13, 2024", files: [1380, 1379] },
  { date: "August 12, 2024", files: [1378] },
  { date: "August 11, 2024", files: [1377] },
  { date: "August 09, 2024", files: [1376] },
  { date: "August 08, 2024", files: [1375] },
  { date: "August 07, 2024", files: [1374] },
  { date: "August 06, 2024", files: [1373] },
  { date: "August 05, 2024", files: [1372] },
  { date: "August 04, 2024", files: [1371] },
  { date: "August 03, 2024", files: [1370] },
  { date: "August 01, 2024", files: [1369, 1368] },
  { date: "July 31, 2024", files: [1367] },
  { date: "July 30, 2024", files: [1366] },
  { date: "July 29, 2024", files: [1365] },
  { date: "July 27, 2024", files: [1364, 1363] },
  { date: "July 26, 2024", files: [1362] },
  { date: "July 25, 2024", files: [1361] },
  { date: "July 24, 2024", files: [1360, 1359] },
  { date: "July 23, 2024", files: [1358] },
  { date: "July 22, 2024", files: [1357] },
  { date: "July 21, 2024", files: [1356] },
  { date: "July 20, 2024", files: [1355] },
  { date: "July 19, 2024", files: [1354, 1353] },
  { date: "July 18, 2024", files: [1352, 1351, 1350] },
  { date: "July 17, 2024", files: [1349] },
  { date: "July 16, 2024", files: [1348, 1347] },
  { date: "July 15, 2024", files: [1346, 1345, 1344] },
  { date: "July 14, 2024", files: [1343] },
  { date: "July 13, 2024", files: [1342, 1341] },
  { date: "July 11, 2024", files: [1340] },
  { date: "July 10, 2024", files: [1339] },
  { date: "July 09, 2024", files: [1338] },
  { date: "July 08, 2024", files: [1337] },
  { date: "July 07, 2024", files: [1336] },
  { date: "July 06, 2024", files: [1335] },
  { date: "July 05, 2024", files: [1334] },
  { date: "July 04, 2024", files: [1333] },
  { date: "July 02, 2024", files: [1332, 1331] },
  { date: "June 30, 2024", files: [1330] },
  { date: "June 29, 2024", files: [1329, 1328] },
  { date: "June 28, 2024", files: [1327] },
  { date: "June 27, 2024", files: [1326, 1325] },
  { date: "June 26, 2024", files: [1324] },
  { date: "June 25, 2024", files: [1323] },
  { date: "June 23, 2024", files: [1322] },
  { date: "June 22, 2024", files: [1321] },
  { date: "June 20, 2024", files: [1320] },
  { date: "June 19, 2024", files: [1319] },
  { date: "June 18, 2024", files: [1318, 1317] },
  { date: "June 17, 2024", files: [1316] },
  { date: "June 16, 2024", files: [1315] },
  { date: "June 15, 2024", files: [1314] },
  { date: "June 14, 2024", files: [1313, 1312] },
  { date: "June 12, 2024", files: [1311] },
  { date: "June 11, 2024", files: [1310, 1309] },
  { date: "June 10, 2024", files: [1308] },
  { date: "June 09, 2024", files: [1307] },
  { date: "June 08, 2024", files: [1306] },
  { date: "June 06, 2024", files: [1305] },
  { date: "June 05, 2024", files: [1304] },
  { date: "June 04, 2024", files: [1303] },
  { date: "June 03, 2024", files: [1302] },
  { date: "June 02, 2024", files: [1301] },
  { date: "June 01, 2024", files: [1300] },
  { date: "May 31, 2024", files: [1299] },
  { date: "May 30, 2024", files: [1298, 1297, 1296] },
  { date: "May 28, 2024", files: [1295, 1294] },
  { date: "May 26, 2024", files: [1293] },
  { date: "May 25, 2024", files: [1292] },
  { date: "May 24, 2024", files: [1291] },
  { date: "May 23, 2024", files: [1290, 1289] },
  { date: "May 22, 2024", files: [1288, 1287, 1286] },
  { date: "May 21, 2024", files: [1285] },
  { date: "May 20, 2024", files: [1284] },
  { date: "May 19, 2024", files: [1283] },
  { date: "May 17, 2024", files: [1282, 1281, 1280] },
  { date: "May 16, 2024", files: [1279] },
  { date: "May 15, 2024", files: [1278, 1277, 1276] },
  { date: "May 14, 2024", files: [1275] },
  { date: "May 13, 2024", files: [1274] },
  { date: "May 11, 2024", files: [1273, 1272] },
  { date: "May 10, 2024", files: [1271] },
  { date: "May 09, 2024", files: [1270] },
  { date: "May 08, 2024", files: [1269, 1268] },
  { date: "May 07, 2024", files: [1267] },
  { date: "May 06, 2024", files: [1266] },
  { date: "May 05, 2024", files: [1265, 1264, 1263, 1262, 1261, 1260] },
  { date: "May 04, 2024", files: [1259] },
  { date: "May 03, 2024", files: [1258] },
  { date: "May 02, 2024", files: [1257, 1256] },
  { date: "May 01, 2024", files: [1255] },
  { date: "April 30, 2024", files: [1254] },
  { date: "April 29, 2024", files: [1253] },
  { date: "April 26, 2024", files: [1252] },
  { date: "April 25, 2024", files: [1251] },
  { date: "April 24, 2024", files: [1250, 1249] },
  { date: "April 22, 2024", files: [1248] },
  { date: "April 19, 2024", files: [1247] },
  { date: "April 18, 2024", files: [1246] },
  { date: "April 17, 2024", files: [1245] },
  { date: "April 16, 2024", files: [1244] },
  { date: "April 15, 2024", files: [1243] },
  { date: "April 13, 2024", files: [1242] },
  { date: "April 12, 2024", files: [1241] },
  { date: "April 11, 2024", files: [1240, 1239] },
  { date: "April 10, 2024", files: [1238] },
  { date: "April 08, 2024", files: [1237, 1236] },
  { date: "April 06, 2024", files: [1235] },
  { date: "April 05, 2024", files: [1234] },
  { date: "April 04, 2024", files: [1233] },
  { date: "April 03, 2024", files: [1232] },
  { date: "April 02, 2024", files: [1231] },
  { date: "April 01, 2024", files: [1230] },
  { date: "March 30, 2024", files: [1229] },
  { date: "March 29, 2024", files: [1228] },
  { date: "March 28, 2024", files: [1227] },
  { date: "March 27, 2024", files: [1226] },
  { date: "March 26, 2024", files: [1225] },
  { date: "March 25, 2024", files: [1224, 1223, 1222, 1221] },
  { date: "March 24, 2024", files: [1220] },
  { date: "March 23, 2024", files: [1219] },
  { date: "March 22, 2024", files: [1218, 1217] },
  { date: "March 21, 2024", files: [1216] },
  { date: "March 19, 2024", files: [1215] },
  { date: "March 18, 2024", files: [1214] },
  { date: "March 17, 2024", files: [1213] },
  { date: "March 16, 2024", files: [1212] },
  { date: "March 14, 2024", files: [1211] },
  { date: "March 13, 2024", files: [1210] },
  { date: "March 12, 2024", files: [1209] },
  { date: "March 11, 2024", files: [1208] },
  { date: "March 10, 2024", files: [1207, 1206] },
  { date: "March 09, 2024", files: [1205] },
  { date: "March 08, 2024", files: [1204] },
  { date: "March 07, 2024", files: [1203] },
  { date: "March 06, 2024", files: [1202] },
  { date: "March 04, 2024", files: [1201] },
  { date: "March 03, 2024", files: [1200] },
  { date: "March 01, 2024", files: [1199] },
  { date: "February 29, 2024", files: [1198] },
  { date: "February 27, 2024", files: [1197] },
  { date: "February 26, 2024", files: [1196, 1195] },
  { date: "February 25, 2024", files: [1194] },
  { date: "February 23, 2024", files: [1193, 1192] },
  { date: "February 22, 2024", files: [1191, 1190] },
  { date: "February 20, 2024", files: [1189] },
  { date: "February 19, 2024", files: [1188] },
  { date: "February 18, 2024", files: [1187] },
  { date: "February 17, 2024", files: [1186, 1185] },
  { date: "February 16, 2024", files: [1184, 1183] },
  { date: "February 14, 2024", files: [1182] },
  { date: "February 13, 2024", files: [1181, 1180] },
  { date: "February 12, 2024", files: [1179] },
  { date: "February 11, 2024", files: [1178] },
  { date: "February 10, 2024", files: [1177] },
  { date: "February 09, 2024", files: [1176] },
  { date: "February 08, 2024", files: [1175] },
  { date: "February 07, 2024", files: [1174] },
  { date: "February 06, 2024", files: [1173] },
  { date: "February 04, 2024", files: [1172, 1171] },
  { date: "February 03, 2024", files: [1170] },
  { date: "February 02, 2024", files: [1169] },
  { date: "February 01, 2024", files: [1168] },
  { date: "January 31, 2024", files: [1167] },
  { date: "January 30, 2024", files: [1166] },
  { date: "January 28, 2024", files: [1165] },
  { date: "January 27, 2024", files: [1164] },
  { date: "January 25, 2024", files: [1163, 1162] },
  { date: "January 24, 2024", files: [1161] },
  { date: "January 23, 2024", files: [1160] },
  { date: "January 22, 2024", files: [1159] },
  { date: "January 21, 2024", files: [1158] },
  { date: "January 20, 2024", files: [1157] },
  { date: "January 19, 2024", files: [1156] },
  { date: "January 18, 2024", files: [1155] },
  { date: "January 17, 2024", files: [1154] },
  { date: "January 16, 2024", files: [1153] },
  { date: "January 15, 2024", files: [1152, 1151] },
  { date: "January 14, 2024", files: [1150] },
  { date: "January 13, 2024", files: [1149] },
  { date: "January 12, 2024", files: [1148, 1147] },
  { date: "January 11, 2024", files: [1146, 1145, 1144] },
  { date: "January 10, 2024", files: [1143] },
  { date: "January 09, 2024", files: [1142] },
  { date: "January 08, 2024", files: [1141] },
  { date: "January 07, 2024", files: [1140] },
  { date: "January 06, 2024", files: [1139] },
  { date: "January 05, 2024", files: [1138] },
  { date: "January 04, 2024", files: [1137] },
  { date: "January 03, 2024", files: [1136] },
  { date: "January 02, 2024", files: [1135] },
  { date: "December 31, 2023", files: [1134] },
  { date: "December 29, 2023", files: [1133] },
  { date: "December 28, 2023", files: [1132] },
  { date: "December 27, 2023", files: [1131, 1130] },
  { date: "December 26, 2023", files: [1129] },
  { date: "December 24, 2023", files: [1128] },
  { date: "December 23, 2023", files: [1127] },
  { date: "December 22, 2023", files: [1126, 1125, 1124] },
  { date: "December 21, 2023", files: [1123] },
  { date: "December 20, 2023", files: [1122] },
  { date: "December 19, 2023", files: [1121] },
  { date: "December 18, 2023", files: [1120] },
  { date: "December 17, 2023", files: [1119] },
  { date: "December 16, 2023", files: [1118] },
  { date: "December 15, 2023", files: [1117, 1116] },
  { date: "December 13, 2023", files: [1115] },
  { date: "December 12, 2023", files: [1114, 1113] },
  { date: "December 11, 2023", files: [1112] },
  { date: "December 10, 2023", files: [1111] },
  { date: "December 09, 2023", files: [1110] },
  { date: "December 08, 2023", files: [1109] },
  { date: "December 07, 2023", files: [1108, 1107] },
  { date: "December 06, 2023", files: [1106] },
  { date: "December 05, 2023", files: [1105] },
  { date: "December 04, 2023", files: [1104] },
  { date: "December 03, 2023", files: [1103] },
  { date: "December 02, 2023", files: [1102] },
  { date: "November 30, 2023", files: [1101] },
  { date: "November 29, 2023", files: [1100, 1099] },
  { date: "November 27, 2023", files: [1098] },
  { date: "November 25, 2023", files: [1097] },
  { date: "November 23, 2023", files: [1096] },
  { date: "November 22, 2023", files: [1095] },
  { date: "November 21, 2023", files: [1094] },
  { date: "November 20, 2023", files: [1093] },
  { date: "November 19, 2023", files: [1092] },
  { date: "November 18, 2023", files: [1091] },
  { date: "November 17, 2023", files: [1090] },
  { date: "November 16, 2023", files: [1089, 1088] },
  { date: "November 15, 2023", files: [1087] },
  { date: "November 14, 2023", files: [1086] },
  { date: "November 13, 2023", files: [1085] },
  { date: "November 12, 2023", files: [1084] },
  { date: "November 11, 2023", files: [1083] },
  { date: "November 09, 2023", files: [1082] },
  { date: "November 08, 2023", files: [1081, 1080] },
  { date: "November 07, 2023", files: [1079] },
  { date: "November 06, 2023", files: [1078] },
  { date: "November 04, 2023", files: [1077, 1076] },
  { date: "November 03, 2023", files: [1075] },
  { date: "November 01, 2023", files: [1074] },
  { date: "October 31, 2023", files: [1073] },
  { date: "October 30, 2023", files: [1072] },
  { date: "October 29, 2023", files: [1071] },
  { date: "October 28, 2023", files: [1070, 1069] },
  { date: "October 27, 2023", files: [1068] },
  { date: "October 26, 2023", files: [1067] },
  { date: "October 25, 2023", files: [1066, 1065] },
  { date: "October 24, 2023", files: [1064] },
  { date: "October 23, 2023", files: [1063] },
  { date: "October 22, 2023", files: [1062] },
  { date: "October 21, 2023", files: [1061] },
  { date: "October 20, 2023", files: [1060] },
  { date: "October 19, 2023", files: [1059] },
  { date: "October 18, 2023", files: [1058, 1057] },
  { date: "October 17, 2023", files: [1056, 1055] },
  { date: "October 16, 2023", files: [1054] },
  { date: "October 15, 2023", files: [1053] },
  { date: "October 14, 2023", files: [1052, 1051, 1050] },
  { date: "October 13, 2023", files: [1049] },
  { date: "October 12, 2023", files: [1048, 1047] },
  { date: "October 11, 2023", files: [1046] },
  { date: "October 10, 2023", files: [1045, 1044] },
  { date: "October 09, 2023", files: [1043, 1042] },
  { date: "October 08, 2023", files: [1041] },
  { date: "October 07, 2023", files: [1040, 1039] },
  { date: "October 06, 2023", files: [1038] },
  { date: "October 05, 2023", files: [1037] },
  { date: "October 04, 2023", files: [1036, 1035] },
  { date: "October 03, 2023", files: [1034] },
  { date: "October 02, 2023", files: [1033] },
  { date: "October 01, 2023", files: [1032] },
  { date: "September 30, 2023", files: [1031] },
  { date: "September 29, 2023", files: [1030, 1029] },
  { date: "September 28, 2023", files: [1028, 1027] },
  { date: "September 27, 2023", files: [1026, 1025] },
  { date: "September 25, 2023", files: [1024] },
  { date: "September 24, 2023", files: [1023] },
  { date: "September 23, 2023", files: [1022] },
  { date: "September 22, 2023", files: [1021] },
  { date: "September 21, 2023", files: [1020] },
  { date: "September 20, 2023", files: [1019] },
  { date: "September 19, 2023", files: [1018] },
  { date: "September 18, 2023", files: [1017] },
  { date: "September 16, 2023", files: [1016] },
  { date: "September 15, 2023", files: [1015] },
  { date: "September 14, 2023", files: [1014] },
  { date: "September 13, 2023", files: [1013] },
  { date: "September 12, 2023", files: [1012] },
  { date: "September 11, 2023", files: [1011] },
  { date: "September 10, 2023", files: [1010] },
  { date: "September 09, 2023", files: [1009] },
  { date: "September 08, 2023", files: [1008, 1007] },
  { date: "September 07, 2023", files: [1006] },
  { date: "September 06, 2023", files: [1005] },
  { date: "September 05, 2023", files: [1004, 1003] },
  { date: "September 04, 2023", files: [1002, 1001] },
  { date: "September 02, 2023", files: [1000] },
  { date: "September 01, 2023", files: [999, 998, 997] },
  { date: "August 31, 2023", files: [996] },
  { date: "August 30, 2023", files: [995] },
  { date: "August 29, 2023", files: [994] },
  { date: "August 28, 2023", files: [993, 992] },
  { date: "August 27, 2023", files: [991] },
  { date: "August 25, 2023", files: [990] },
  { date: "August 24, 2023", files: [989, 988] },
  { date: "August 23, 2023", files: [987, 986] },
  { date: "August 22, 2023", files: [985] },
  { date: "August 21, 2023", files: [984] },
  { date: "August 20, 2023", files: [983] },
  { date: "August 19, 2023", files: [982] },
  { date: "August 18, 2023", files: [981] },
  { date: "August 17, 2023", files: [980, 979] },
  { date: "August 16, 2023", files: [978, 977, 976] },
  { date: "August 15, 2023", files: [975] },
  { date: "August 14, 2023", files: [974] },
  { date: "August 13, 2023", files: [973, 972] },
  { date: "August 12, 2023", files: [971] },
  { date: "August 11, 2023", files: [970, 969] },
  { date: "August 10, 2023", files: [968, 967, 966] },
  { date: "August 09, 2023", files: [965, 964, 963, 962, 961] },
  { date: "August 08, 2023", files: [960, 959] },
  { date: "August 07, 2023", files: [958, 957, 956] },
  { date: "August 06, 2023", files: [955] },
  { date: "August 05, 2023", files: [954, 953, 952] },
  { date: "August 04, 2023", files: [951] },
  { date: "August 03, 2023", files: [950, 949, 948, 947] },
  { date: "August 02, 2023", files: [946, 945] },
  { date: "August 01, 2023", files: [944] },
  { date: "July 30, 2023", files: [943] },
  { date: "July 29, 2023", files: [942] },
  { date: "July 28, 2023", files: [941] },
  { date: "July 27, 2023", files: [940] },
  { date: "July 26, 2023", files: [939, 938] },
  { date: "July 25, 2023", files: [937] },
  { date: "July 24, 2023", files: [936] },
  { date: "July 23, 2023", files: [935] },
  { date: "July 22, 2023", files: [934] },
  { date: "July 21, 2023", files: [933, 932] },
  { date: "July 20, 2023", files: [931, 930, 929, 928] },
  { date: "July 19, 2023", files: [927] },
  { date: "July 18, 2023", files: [926, 925] },
  { date: "July 17, 2023", files: [924, 923] },
  { date: "July 16, 2023", files: [922, 921] },
  { date: "July 15, 2023", files: [920] },
  { date: "July 14, 2023", files: [919] },
  { date: "July 13, 2023", files: [918] },
  { date: "July 12, 2023", files: [917] },
  { date: "July 11, 2023", files: [916, 915] },
  { date: "July 10, 2023", files: [914, 913] },
  { date: "July 09, 2023", files: [912, 911] },
  { date: "July 08, 2023", files: [910, 909] },
  { date: "July 07, 2023", files: [908, 907] },
  { date: "July 06, 2023", files: [906] },
  { date: "July 05, 2023", files: [905] },
  { date: "July 04, 2023", files: [904] },
  { date: "July 02, 2023", files: [903] },
  { date: "July 01, 2023", files: [902] },
  { date: "June 30, 2023", files: [901] },
  { date: "June 29, 2023", files: [900] },
  { date: "May 16, 2022", files: [899, 898, 897] },
  { date: "May 15, 2022", files: [896, 895] },
  { date: "May 14, 2022", files: [894, 893] },
  { date: "May 13, 2022", files: [892, 891, 890] },
  { date: "May 12, 2022", files: [889, 888, 887] },
  { date: "May 11, 2022", files: [886, 885, 884, 883] },
  { date: "May 10, 2022", files: [882, 881, 880, 879, 878, 877, 876, 875] },
  { date: "May 09, 2022", files: [874, 873, 872, 871] },
  { date: "May 08, 2022", files: [870, 869, 868, 867] },
  { date: "May 07, 2022", files: [866, 865, 864, 863, 862, 861] },
  { date: "May 06, 2022", files: [860, 859, 858, 857, 856, 855] },
  { date: "May 05, 2022", files: [854, 853, 852, 851] },
  { date: "May 04, 2022", files: [850, 849, 848, 847, 846] },
  { date: "May 03, 2022", files: [845, 844, 843] },
  { date: "May 02, 2022", files: [842, 841] },
  { date: "May 01, 2022", files: [840, 839, 838] },
  { date: "April 30, 2022", files: [837, 836, 835] },
  { date: "April 29, 2022", files: [834, 833] },
  { date: "April 28, 2022", files: [832, 831, 830] },
  { date: "April 27, 2022", files: [829, 828, 827] },
  { date: "April 26, 2022", files: [826] },
  { date: "April 25, 2022", files: [825, 824, 823] },
  { date: "April 24, 2022", files: [822] },
  { date: "April 23, 2022", files: [821, 820, 819] },
  { date: "April 22, 2022", files: [818, 817, 816] },
  { date: "April 21, 2022", files: [815, 814] },
  { date: "April 20, 2022", files: [813, 812] },
  { date: "April 19, 2022", files: [811, 810, 809] },
  { date: "April 18, 2022", files: [808, 807] },
  { date: "April 17, 2022", files: [806, 805] },
  { date: "April 16, 2022", files: [804, 803] },
  { date: "April 15, 2022", files: [802, 801, 800] },
  { date: "April 14, 2022", files: [799, 798] },
  { date: "April 13, 2022", files: [797, 796, 795] },
  { date: "April 12, 2022", files: [794, 793] },
  { date: "April 11, 2022", files: [792, 791, 790] },
  { date: "April 10, 2022", files: [789, 788, 787, 786] },
  { date: "April 09, 2022", files: [785, 784] },
  { date: "April 08, 2022", files: [783, 782] },
  { date: "April 07, 2022", files: [781, 780] },
  { date: "April 06, 2022", files: [779] },
  { date: "April 05, 2022", files: [778, 777, 776] },
  { date: "April 04, 2022", files: [775, 774] },
  { date: "April 03, 2022", files: [773, 772, 771] },
  { date: "April 02, 2022", files: [770] },
  { date: "April 01, 2022", files: [769, 768] },
  { date: "March 31, 2022", files: [767, 766] },
  { date: "March 30, 2022", files: [765, 764] },
  { date: "March 29, 2022", files: [763] },
  { date: "March 28, 2022", files: [762, 761] },
  { date: "March 27, 2022", files: [760, 759] },
  { date: "March 26, 2022", files: [758, 757] },
  { date: "March 25, 2022", files: [756, 755, 754] },
  { date: "March 24, 2022", files: [753, 752] },
  { date: "March 23, 2022", files: [751, 750, 749] },
  { date: "March 22, 2022", files: [748, 747] },
  { date: "March 21, 2022", files: [746, 745] },
  { date: "March 20, 2022", files: [744, 743] },
  { date: "March 19, 2022", files: [742, 741] },
  { date: "March 18, 2022", files: [740, 739] },
  { date: "March 17, 2022", files: [738, 737] },
  { date: "March 16, 2022", files: [736, 735, 734] },
  { date: "March 15, 2022", files: [733] },
  { date: "March 14, 2022", files: [732, 731] },
  { date: "March 13, 2022", files: [730, 729, 728] },
  { date: "March 12, 2022", files: [727] },
  { date: "March 11, 2022", files: [726, 725, 724] },
  { date: "March 10, 2022", files: [723, 722] },
  { date: "March 09, 2022", files: [721, 720] },
  { date: "March 08, 2022", files: [719, 718] },
  { date: "March 07, 2022", files: [717, 716] },
  { date: "March 06, 2022", files: [715] },
  { date: "March 05, 2022", files: [714, 713] },
  { date: "March 04, 2022", files: [712, 711, 710] },
  { date: "March 03, 2022", files: [709, 708] },
  { date: "March 02, 2022", files: [707, 706, 705] },
  { date: "March 01, 2022", files: [704, 703] },
  { date: "February 28, 2022", files: [702] },
  { date: "February 27, 2022", files: [701, 700] },
  { date: "February 26, 2022", files: [699, 698, 697] },
  { date: "February 25, 2022", files: [696, 695, 694] },
  { date: "February 24, 2022", files: [693, 692, 691] },
  { date: "February 23, 2022", files: [690] },
  { date: "February 22, 2022", files: [689, 688, 687] },
  { date: "February 21, 2022", files: [686, 685] },
  { date: "February 20, 2022", files: [684] },
  { date: "February 19, 2022", files: [683] },
  { date: "February 18, 2022", files: [682, 681] },
  { date: "February 17, 2022", files: [680, 679, 678] },
  { date: "February 16, 2022", files: [677] },
  { date: "February 15, 2022", files: [676, 675] },
  { date: "February 14, 2022", files: [674, 673, 672] },
  { date: "February 13, 2022", files: [671, 670, 669] },
  { date: "February 12, 2022", files: [668, 667] },
  { date: "February 11, 2022", files: [666, 665, 664, 663] },
  { date: "February 10, 2022", files: [662, 661] },
  { date: "February 09, 2022", files: [660, 659, 658] },
  { date: "February 08, 2022", files: [657, 656] },
  { date: "February 07, 2022", files: [655, 654, 653] },
  { date: "February 06, 2022", files: [652, 651] },
  { date: "February 05, 2022", files: [650, 649, 648] },
  { date: "February 04, 2022", files: [647] },
  { date: "February 03, 2022", files: [646, 645, 644] },
  { date: "February 02, 2022", files: [643, 642, 641] },
  { date: "February 01, 2022", files: [640, 639, 638] },
  { date: "January 31, 2022", files: [637, 636] },
  { date: "January 30, 2022", files: [635, 634] },
  { date: "January 29, 2022", files: [633, 632, 631] },
  { date: "January 28, 2022", files: [630, 629] },
  { date: "January 27, 2022", files: [628, 627] },
  { date: "January 26, 2022", files: [626, 625, 624] },
  { date: "January 25, 2022", files: [623, 622] },
  { date: "January 24, 2022", files: [621, 620, 619] },
  { date: "January 23, 2022", files: [618, 617] },
  { date: "January 22, 2022", files: [616, 615, 614] },
  { date: "January 21, 2022", files: [613, 612, 611] },
  { date: "January 20, 2022", files: [610, 609, 608] },
  { date: "January 19, 2022", files: [607, 606, 605, 604] },
  { date: "January 18, 2022", files: [603, 602, 601, 600] },
  { date: "January 17, 2022", files: [599, 598] },
  { date: "January 16, 2022", files: [597, 596] },
  { date: "January 15, 2022", files: [595, 594, 593] },
  { date: "January 14, 2022", files: [592, 591, 590] },
  { date: "January 13, 2022", files: [589, 588, 587] },
  { date: "January 12, 2022", files: [586, 585] },
  { date: "January 11, 2022", files: [584, 583] },
  { date: "January 10, 2022", files: [582, 581] },
  { date: "January 09, 2022", files: [580] },
  { date: "January 08, 2022", files: [579] },
  { date: "January 07, 2022", files: [578, 577] },
  { date: "January 06, 2022", files: [576] },
  { date: "January 05, 2022", files: [575, 574] },
  { date: "January 04, 2022", files: [573] },
  { date: "January 03, 2022", files: [572, 571] },
  { date: "January 02, 2022", files: [570] },
  { date: "January 01, 2022", files: [569, 568] },
  { date: "December 31, 2021", files: [567, 566, 565] },
  { date: "December 30, 2021", files: [564, 563, 562] },
  { date: "December 29, 2021", files: [561] },
  { date: "December 28, 2021", files: [560, 559] },
  { date: "December 27, 2021", files: [558, 557] },
  { date: "December 25, 2021", files: [556, 555] },
  { date: "December 24, 2021", files: [554, 553] },
  { date: "December 23, 2021", files: [552, 551, 550, 549] },
  { date: "December 22, 2021", files: [548] },
  { date: "December 21, 2021", files: [547, 546] },
  { date: "December 20, 2021", files: [545, 544] },
  { date: "December 19, 2021", files: [543] },
  { date: "December 18, 2021", files: [542, 541, 540] },
  { date: "December 17, 2021", files: [539, 538] },
  { date: "December 16, 2021", files: [537, 536, 535, 534] },
  { date: "December 15, 2021", files: [533, 532, 531] },
  { date: "December 14, 2021", files: [530, 529] },
  { date: "December 13, 2021", files: [528, 527, 526] },
  { date: "December 12, 2021", files: [525, 524, 523] },
  { date: "December 11, 2021", files: [522, 521] },
  { date: "December 10, 2021", files: [520, 519] },
  { date: "December 09, 2021", files: [518, 517] },
  { date: "December 08, 2021", files: [516, 515, 514] },
  { date: "December 07, 2021", files: [513, 512, 511] },
  { date: "December 06, 2021", files: [510, 509] },
  { date: "December 05, 2021", files: [508, 507] },
  { date: "December 04, 2021", files: [506] },
  { date: "December 03, 2021", files: [505, 504] },
  { date: "December 02, 2021", files: [503, 502, 501] },
  { date: "December 01, 2021", files: [500, 499, 498] },
  { date: "November 30, 2021", files: [497] },
  { date: "November 29, 2021", files: [496, 495] },
  { date: "November 27, 2021", files: [494] },
  { date: "November 26, 2021", files: [493, 492] },
  { date: "November 25, 2021", files: [491] },
  { date: "November 24, 2021", files: [490, 489] },
  { date: "November 23, 2021", files: [488] },
  { date: "November 22, 2021", files: [487] },
  { date: "November 21, 2021", files: [486, 485] },
  { date: "November 20, 2021", files: [484] },
  { date: "November 19, 2021", files: [483] },
  { date: "November 18, 2021", files: [482] },
  { date: "November 17, 2021", files: [481, 480] },
  { date: "November 16, 2021", files: [479, 478] },
  { date: "November 15, 2021", files: [477] },
  { date: "November 14, 2021", files: [476] },
  { date: "November 13, 2021", files: [475, 474] },
  { date: "November 12, 2021", files: [473, 472] },
  { date: "November 11, 2021", files: [471] },
  { date: "November 10, 2021", files: [470] },
  { date: "November 09, 2021", files: [469, 468] },
  { date: "November 08, 2021", files: [467] },
  { date: "November 07, 2021", files: [466, 465] },
  { date: "November 06, 2021", files: [464, 463] },
  { date: "November 05, 2021", files: [462, 461] },
  { date: "November 04, 2021", files: [460, 459, 458, 457] },
  { date: "November 03, 2021", files: [456] },
  { date: "November 02, 2021", files: [455, 454] },
  { date: "November 01, 2021", files: [453, 452] },
  { date: "October 31, 2021", files: [451] },
  { date: "October 30, 2021", files: [450, 449] },
  { date: "October 29, 2021", files: [448] },
  { date: "October 28, 2021", files: [447, 446] },
  { date: "October 27, 2021", files: [445] },
  { date: "October 26, 2021", files: [444, 443] },
  { date: "October 25, 2021", files: [442, 441] },
  { date: "October 24, 2021", files: [440, 439] },
  { date: "October 23, 2021", files: [438, 437] },
  { date: "October 22, 2021", files: [436, 435] },
  { date: "October 21, 2021", files: [434, 433, 432] },
  { date: "October 20, 2021", files: [431, 430] },
  { date: "October 19, 2021", files: [429] },
  { date: "October 18, 2021", files: [428, 427] },
  { date: "October 17, 2021", files: [426, 425] },
  { date: "October 16, 2021", files: [424, 423] },
  { date: "October 15, 2021", files: [422, 421, 420] },
  { date: "October 14, 2021", files: [419, 418] },
  { date: "October 13, 2021", files: [417, 416] },
  { date: "October 12, 2021", files: [415, 414] },
  { date: "October 11, 2021", files: [413, 412] },
  { date: "October 10, 2021", files: [411, 410] },
  { date: "October 09, 2021", files: [409] },
  { date: "October 08, 2021", files: [408, 407, 406] },
  { date: "October 07, 2021", files: [405, 404, 403] },
  { date: "October 06, 2021", files: [402, 401] },
  { date: "October 05, 2021", files: [400, 399, 398] },
  { date: "October 04, 2021", files: [397, 396] },
  { date: "October 03, 2021", files: [395, 394] },
  { date: "October 02, 2021", files: [393, 392] },
  { date: "October 01, 2021", files: [391, 390] },
  { date: "September 30, 2021", files: [389] },
  { date: "September 29, 2021", files: [388, 387] },
  { date: "September 28, 2021", files: [386, 385] },
  { date: "September 27, 2021", files: [384, 383] },
  { date: "September 26, 2021", files: [382] },
  { date: "September 25, 2021", files: [381, 380] },
  { date: "September 24, 2021", files: [379, 378] },
  { date: "September 23, 2021", files: [377, 376] },
  { date: "September 22, 2021", files: [375, 374] },
  { date: "September 21, 2021", files: [373] },
  { date: "September 20, 2021", files: [372] },
  { date: "September 19, 2021", files: [371] },
  { date: "September 18, 2021", files: [370, 369] },
  { date: "September 17, 2021", files: [368, 367] },
  { date: "September 16, 2021", files: [366] },
  { date: "September 15, 2021", files: [365, 364] },
  { date: "September 14, 2021", files: [363] },
  { date: "September 13, 2021", files: [362] },
  { date: "September 12, 2021", files: [361, 360, 359] },
  { date: "September 11, 2021", files: [358, 357] },
  { date: "September 10, 2021", files: [356] },
  { date: "September 09, 2021", files: [355, 354] },
  { date: "September 08, 2021", files: [353] },
  { date: "September 07, 2021", files: [352] },
  { date: "September 06, 2021", files: [351] },
  { date: "September 05, 2021", files: [350, 349] },
  { date: "September 04, 2021", files: [348] },
  { date: "September 03, 2021", files: [347, 346] },
  { date: "September 02, 2021", files: [345, 344] },
  { date: "September 01, 2021", files: [343] },
  { date: "August 31, 2021", files: [342] },
  { date: "August 29, 2021", files: [341] },
  { date: "August 28, 2021", files: [340, 339] },
  { date: "August 27, 2021", files: [338] },
  { date: "August 26, 2021", files: [337] },
  { date: "August 25, 2021", files: [336, 335] },
  { date: "August 24, 2021", files: [334] },
  { date: "August 23, 2021", files: [333] },
  { date: "August 22, 2021", files: [332] },
  { date: "August 21, 2021", files: [331] },
  { date: "August 20, 2021", files: [330] },
  { date: "August 19, 2021", files: [329] },
  { date: "August 18, 2021", files: [328, 327] },
  { date: "August 17, 2021", files: [326] },
  { date: "August 16, 2021", files: [325, 324] },
  { date: "August 15, 2021", files: [323] },
  { date: "August 14, 2021", files: [322, 321] },
  { date: "August 13, 2021", files: [320, 319] },
  { date: "August 12, 2021", files: [318, 317] },
  { date: "August 11, 2021", files: [316, 315] },
  { date: "August 10, 2021", files: [314] },
  { date: "August 09, 2021", files: [313, 312] },
  { date: "August 08, 2021", files: [311] },
  { date: "August 07, 2021", files: [310] },
  { date: "August 06, 2021", files: [309] },
  { date: "August 05, 2021", files: [308] },
  { date: "August 04, 2021", files: [307, 306] },
  { date: "August 03, 2021", files: [305] },
  { date: "August 02, 2021", files: [304] },
  { date: "August 01, 2021", files: [303, 302] },
  { date: "July 31, 2021", files: [301, 300] },
  { date: "July 30, 2021", files: [299] },
  { date: "July 29, 2021", files: [298] },
  { date: "July 28, 2021", files: [297] },
  { date: "July 27, 2021", files: [296] },
  { date: "July 26, 2021", files: [295] },
  { date: "July 25, 2021", files: [294] },
  { date: "July 24, 2021", files: [293] },
  { date: "July 23, 2021", files: [292, 291] },
  { date: "July 22, 2021", files: [290, 289, 288] },
  { date: "July 21, 2021", files: [287, 286] },
  { date: "July 20, 2021", files: [285, 284, 283] },
  { date: "July 19, 2021", files: [282] },
  { date: "July 18, 2021", files: [281, 280] },
  { date: "July 17, 2021", files: [279, 278] },
  { date: "July 16, 2021", files: [277, 276] },
  { date: "July 15, 2021", files: [275, 274, 273] },
  { date: "July 14, 2021", files: [272, 271, 270] },
  { date: "July 13, 2021", files: [269, 268, 267] },
  { date: "July 12, 2021", files: [266, 265] },
  { date: "July 11, 2021", files: [264, 263] },
  { date: "July 10, 2021", files: [262, 261, 260, 259, 258] },
  { date: "July 09, 2021", files: [257, 256, 255] },
  { date: "July 08, 2021", files: [254] },
  { date: "July 07, 2021", files: [253] },
  { date: "July 06, 2021", files: [252] },
  { date: "July 05, 2021", files: [251] },
  { date: "July 02, 2021", files: [250] },
  { date: "July 01, 2021", files: [249] },
  { date: "June 30, 2021", files: [248] },
  { date: "June 29, 2021", files: [247, 246] },
  { date: "June 28, 2021", files: [245, 244, 243] },
  { date: "June 27, 2021", files: [242, 241, 240] },
  { date: "June 26, 2021", files: [239, 238] },
  { date: "June 25, 2021", files: [237, 236, 235, 234] },
  { date: "June 24, 2021", files: [233, 232] },
  { date: "June 23, 2021", files: [231] },
  { date: "June 22, 2021", files: [230, 229] },
  { date: "June 21, 2021", files: [228] },
  { date: "June 20, 2021", files: [227, 226, 225] },
  { date: "June 19, 2021", files: [224, 223, 222] },
  { date: "June 18, 2021", files: [221] },
  { date: "June 17, 2021", files: [220, 219] },
  { date: "June 16, 2021", files: [218, 217] },
  { date: "June 15, 2021", files: [216] },
  { date: "June 14, 2021", files: [215, 214] },
  { date: "June 13, 2021", files: [213] },
  { date: "June 12, 2021", files: [212, 211] },
  { date: "June 11, 2021", files: [210, 209, 208] },
  { date: "June 10, 2021", files: [207] },
  { date: "June 09, 2021", files: [206, 205] },
  { date: "June 08, 2021", files: [204, 203] },
  { date: "June 07, 2021", files: [202, 201] },
  { date: "June 06, 2021", files: [200, 199] },
  { date: "June 05, 2021", files: [198, 197] },
  { date: "June 04, 2021", files: [196, 195] },
  { date: "June 03, 2021", files: [194] },
  { date: "June 02, 2021", files: [193, 192] },
  { date: "June 01, 2021", files: [191] },
  { date: "May 31, 2021", files: [190] },
  { date: "May 30, 2021", files: [189] },
  { date: "May 29, 2021", files: [188] },
  { date: "May 28, 2021", files: [187, 186] },
  { date: "May 27, 2021", files: [185, 184] },
  { date: "May 26, 2021", files: [183, 182] },
  { date: "May 25, 2021", files: [181, 180, 179] },
  { date: "May 24, 2021", files: [178] },
  { date: "May 23, 2021", files: [177] },
  { date: "May 22, 2021", files: [176, 175] },
  { date: "May 21, 2021", files: [174, 173] },
  { date: "May 20, 2021", files: [172] },
  { date: "May 19, 2021", files: [171] },
  { date: "May 18, 2021", files: [170, 169] },
  { date: "May 17, 2021", files: [168] },
  { date: "May 16, 2021", files: [167, 166] },
  { date: "May 15, 2021", files: [165] },
  { date: "May 14, 2021", files: [164, 163] },
  { date: "May 13, 2021", files: [162] },
  { date: "May 12, 2021", files: [161, 160] },
  { date: "May 11, 2021", files: [159] },
  { date: "May 10, 2021", files: [158] },
  { date: "May 09, 2021", files: [157] },
  { date: "May 08, 2021", files: [156] },
  { date: "May 07, 2021", files: [155, 154] },
  { date: "May 05, 2021", files: [153] },
  { date: "May 04, 2021", files: [152] },
  { date: "May 03, 2021", files: [151] },
  { date: "May 02, 2021", files: [150] },
  { date: "May 01, 2021", files: [149] },
  { date: "April 30, 2021", files: [148, 147] },
  { date: "April 29, 2021", files: [146, 145] },
  { date: "April 28, 2021", files: [144] },
  { date: "April 27, 2021", files: [143, 142, 141, 140] },
  { date: "April 26, 2021", files: [139] },
  { date: "April 25, 2021", files: [138] },
  { date: "April 24, 2021", files: [137, 136] },
  { date: "April 23, 2021", files: [135, 134] },
  { date: "April 22, 2021", files: [133, 132] },
  { date: "April 21, 2021", files: [131, 130] },
  { date: "April 20, 2021", files: [129, 128, 127] },
  { date: "April 19, 2021", files: [126, 125] },
  { date: "April 18, 2021", files: [124, 123] },
  { date: "April 17, 2021", files: [122] },
  { date: "April 16, 2021", files: [121, 120, 119] },
  { date: "April 15, 2021", files: [118, 117] },
  { date: "April 14, 2021", files: [116, 115] },
  { date: "April 13, 2021", files: [114] },
  { date: "April 12, 2021", files: [113] },
  { date: "April 11, 2021", files: [112] },
  { date: "April 10, 2021", files: [111] },
  { date: "April 09, 2021", files: [110, 109] },
  { date: "April 07, 2021", files: [108] },
  { date: "April 06, 2021", files: [107] },
  { date: "April 05, 2021", files: [106] },
  { date: "April 03, 2021", files: [105] },
  { date: "April 02, 2021", files: [104, 103] },
  { date: "April 01, 2021", files: [102] },
  { date: "March 31, 2021", files: [101] },
  { date: "March 30, 2021", files: [100] },
  { date: "March 29, 2021", files: [99] },
  { date: "March 28, 2021", files: [98, 97] },
  { date: "March 27, 2021", files: [96] },
  { date: "March 26, 2021", files: [95, 94] },
  { date: "March 24, 2021", files: [93] },
  { date: "March 23, 2021", files: [92] },
  { date: "March 22, 2021", files: [91] },
  { date: "March 21, 2021", files: [90] },
  { date: "March 20, 2021", files: [89] },
  { date: "March 19, 2021", files: [88, 87] },
  { date: "March 18, 2021", files: [86] },
  { date: "March 17, 2021", files: [85] },
  { date: "March 16, 2021", files: [84] },
  { date: "March 15, 2021", files: [83, 82] },
  { date: "March 14, 2021", files: [81] },
  { date: "March 13, 2021", files: [80, 79] },
  { date: "March 12, 2021", files: [78] },
  { date: "March 11, 2021", files: [77] },
  { date: "March 10, 2021", files: [76, 75] },
  { date: "March 09, 2021", files: [74] },
  { date: "March 08, 2021", files: [73] },
  { date: "March 07, 2021", files: [72] },
  { date: "March 06, 2021", files: [71, 70] },
  { date: "March 05, 2021", files: [69, 68] },
  { date: "March 04, 2021", files: [67, 66] },
  { date: "March 03, 2021", files: [65, 64] },
  { date: "March 02, 2021", files: [63, 62] },
  { date: "March 01, 2021", files: [61] },
  { date: "February 28, 2021", files: [60] },
  { date: "February 27, 2021", files: [59, 58] },
  { date: "February 26, 2021", files: [57] },
  { date: "February 25, 2021", files: [56] },
  { date: "February 24, 2021", files: [55] },
  { date: "February 23, 2021", files: [54] },
  { date: "February 22, 2021", files: [53] },
  { date: "February 20, 2021", files: [52] },
  { date: "February 19, 2021", files: [51, 50, 49] },
  { date: "February 18, 2021", files: [48] },
  { date: "February 17, 2021", files: [47] },
  { date: "February 15, 2021", files: [46] },
  { date: "February 13, 2021", files: [45] },
  { date: "February 12, 2021", files: [44, 43] },
  { date: "February 11, 2021", files: [42, 41] },
  { date: "February 09, 2021", files: [40] },
  { date: "February 08, 2021", files: [39] },
  { date: "February 06, 2021", files: [38] },
  { date: "February 05, 2021", files: [37] },
  { date: "February 03, 2021", files: [36] },
  { date: "February 01, 2021", files: [35] },
  { date: "January 31, 2021", files: [34] },
  { date: "January 30, 2021", files: [33] },
  { date: "January 29, 2021", files: [32, 31] },
  { date: "January 28, 2021", files: [30] },
  { date: "January 27, 2021", files: [29, 28] },
  { date: "January 26, 2021", files: [27] },
  { date: "January 25, 2021", files: [26] },
  { date: "January 23, 2021", files: [25, 24] },
  { date: "January 22, 2021", files: [23] },
  { date: "January 21, 2021", files: [22, 21] },
  { date: "January 19, 2021", files: [20, 19] },
  { date: "January 18, 2021", files: [18] },
  { date: "January 17, 2021", files: [17] },
  { date: "January 16, 2021", files: [16] },
  { date: "January 15, 2021", files: [15] },
  { date: "January 14, 2021", files: [14] },
  { date: "January 13, 2021", files: [13, 12] },
  { date: "January 12, 2021", files: [11, 10] },
  { date: "January 11, 2021", files: [9, 8, 7] },
  { date: "January 10, 2021", files: [6, 5, 4] },
  { date: "January 09, 2021", files: [3, 2, 1] },
];

function sortByDate(a, b) {
  const dateA = new Date(a.date);
  const dateB = new Date(b.date);
  return dateB - dateA;
}

export function getAllMessages() {
  return messageData.sort(sortByDate);
}