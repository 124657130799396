const configs = {
  production: {
    baseUrl: 'https://trnt.la',
    htmlPath: '/HTML/messages'
  },
  staging: {
    baseUrl: window.location.origin,
    htmlPath: '/HTML/messages'
  },
  development: {
    baseUrl: 'http://localhost:3000',
    htmlPath: '/HTML/messages'
  }
};

const getEnvironment = () => {
  if (window.location.hostname === 'trnt.la') return 'production';
  if (window.location.hostname.includes('web.app') || 
      window.location.hostname.includes('firebaseapp.com')) return 'staging';
  return 'development';
};

export const config = configs[getEnvironment()]; 